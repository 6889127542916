import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import {
  PaymentMethod,
  ReservedPaymentOptionIds,
  BusinessInfo,
  GeneralErrorType,
  CouponsErrorType,
} from '../../types/types';
import {
  getActiveSchedule,
  getServiceType,
  mapCatalogServiceToService,
  Service,
} from '../mappers/service.mapper';
import { ServiceType, ServicePayment } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { TFunction } from '../../components/BookingsForm/controller';
import { FormApi } from '../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyState } from '../dummies/dummy-data';
import { isOfferedAsOneTime, isOfferedAsPricingPlan } from '../payment/payment';

export type FormErrors = CouponsErrorType | GeneralErrorType;

export type FormState = {
  service: Service;
  isPricingPlanInstalled: boolean;
  hasCoupon: boolean;
  businessInfo: BusinessInfo;
  activeFeatures: GetActiveFeaturesResponse;
  slotAvailability: SlotAvailability;
  numberOfParticipants: number;
  couponDiscount?: number;
  pricingPlanDetails?: PaidPlans;
  memberDetails?: Member;
  errors: FormErrors[];
  selectedPaymentOptionId?: string;
  showCouponInput: boolean;
};

export async function createInitialState({
  t,
  flowApi,
  wixSdkAdapter,
  formApi,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;
  if (wixSdkAdapter.isEditorMode()) {
    const catalogData = await formApi.getCatalogData();
    return createDummyState(flowApi, catalogData.businessInfo);
  }
  const {
    hasCoupon,
    catalogData,
    listSlots,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    isPricingPlanInstalled,
    errors,
  } = await fetchInitialData({ formApi, controllerConfig, wixSdkAdapter });

  if (errors.length > 0) {
    return {
      errors,
    } as FormState;
  }

  const service = mapCatalogServiceToService({
    catalogData: catalogData!,
    slotAvailability: slotAvailability!,
    pricingPlanDetails,
    t,
    listSlots,
  });

  const selectedPaymentOptionId = getDefaultPaymentOptionId(
    settings,
    service.payment,
    pricingPlanDetails,
  );

  return {
    activeFeatures: catalogData!.activeFeatures,
    service,
    businessInfo: catalogData!.businessInfo,
    slotAvailability: slotAvailability!,
    isPricingPlanInstalled: isPricingPlanInstalled!,
    couponDiscount: 0,
    pricingPlanDetails,
    memberDetails,
    numberOfParticipants: 1,
    errors: [],
    selectedPaymentOptionId,
    hasCoupon: hasCoupon!,
    showCouponInput: false,
  };
}

export const getDefaultPaymentOptionId = (
  settings: ControllerFlowAPI['settings'],
  ServicePayment: ServicePayment,
  pricingPlanDetails?: PaidPlans,
) => {
  const planId = pricingPlanDetails?.defaultPlan?.paidPlan?.planId;
  if (planId) {
    return planId;
  } else if (
    isOfferedAsOneTime(ServicePayment) &&
    !isOfferedAsPricingPlan(ServicePayment)
  ) {
    return ReservedPaymentOptionIds.SingleSession;
  } else {
    return settings.get(settingsParams.defaultPaymentMethod) ===
      PaymentMethod.SINGLE
      ? ReservedPaymentOptionIds.SingleSession
      : ReservedPaymentOptionIds.BuyAPricingPlan;
  }
};

const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
}) => {
  let errors: FormErrors[] = [];
  const serviceId = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.SERVICE_ID,
  );

  if (!serviceId) {
    errors = [...errors, GeneralErrorType.INVALID_SERVICE_ID];
    return { errors };
  }

  const slotAvailabilityParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.AVAILABILITY_SLOT,
  );
  let slotAvailability: SlotAvailability | undefined = decodeSlotAvailability(
    slotAvailabilityParam,
  );

  const resourceId = slotAvailability?.slot!.resource!.id!;
  const startTime = slotAvailability?.slot!.startDate!;
  const user = controllerConfig.wixCodeApi.user.currentUser;
  const isLoggedInUser = user.loggedIn;
  const shouldGetPricingPlanDetails = isLoggedInUser && startTime;

  const [
    hasCoupon,
    catalogData,
    memberDetails,
    pricingPlanDetails,
    isPricingPlanInstalled,
  ] = await Promise.all([
    formApi.isCouponsDefinedOnService().catch(() => undefined),
    formApi.getCatalogData({ serviceId, resourceId }).catch(() => undefined),
    isLoggedInUser ? formApi.getMemberDetails(user.id) : undefined,
    shouldGetPricingPlanDetails
      ? formApi
          .getPricingPlanDetails({
            serviceId,
            startTime,
          })
          .catch(() => undefined)
      : undefined,
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
  ]);

  const activeSchedule = getActiveSchedule(catalogData?.service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const isCatalogDataValid = catalogData && activeSchedule;
  if (!isCatalogDataValid) {
    errors = [...errors, GeneralErrorType.INVALID_CATALOG_DATA];
    return { errors };
  }

  const type = getServiceType(activeSchedule);
  const isCourse = type === ServiceType.COURSE;

  const getListSlots = () =>
    isCourse && firstSessionStart && lastSessionEnd
      ? formApi.getSlots({
          firstSessionStart,
          lastSessionEnd,
          scheduleId,
        })
      : {};
  const [listSlots, courseAvailability] = await Promise.all([
    getListSlots(),
    isCourse ? formApi.getAvailability({ scheduleId }) : {},
  ]);

  if (isCourse) {
    slotAvailability = {
      openSpots:
        Number(courseAvailability.capacity) -
        Number(courseAvailability.totalNumberOfParticipants),
      slot: {
        startDate: firstSessionStart,
        endDate: lastSessionEnd,
      },
    };
  }

  const isSlotAvailabilityValid = isCourse || slotAvailability?.slot;
  if (!isSlotAvailabilityValid) {
    errors = [...errors, GeneralErrorType.INVALID_SLOT_AVAILABILITY];
    return { errors };
  }

  return {
    catalogData,
    listSlots,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    isPricingPlanInstalled,
    hasCoupon,
    errors,
  };
};

const decodeSlotAvailability = (
  slotAvailabilityParam: string,
): SlotAvailability | undefined => {
  try {
    return JSON.parse(atob(slotAvailabilityParam));
  } catch {
    return undefined;
  }
};
